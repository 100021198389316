import { SortDirection } from '@air/api/types';
import { ArrowDown, Check as CheckIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { ButtonGroup } from '@air/primitive-button-group';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { getConfigFieldLabel } from '~/constants/SortFieldsMap';

export interface SortMenuProps {
  direction: SortDirection;
  currentSortBy: string;
  onSortChange: (sortBy: string, direction: SortDirection) => void;
  sortOptions: readonly string[];
  'data-testid'?: string;
}

export const SortMenu = memo(
  ({ onSortChange, currentSortBy, direction, sortOptions, 'data-testid': testId }: SortMenuProps) => {
    const supportsDirection = currentSortBy !== 'customSort';

    const onSortDirectionChange = (newDirection: SortDirection) => onSortChange(currentSortBy, newDirection);

    const options = useMemo(() => {
      const optionsArray: DropdownMenuOption[] = [
        {
          id: 'sort-by',
          label: 'Sort by',
          type: 'title',
        },
      ];

      sortOptions.map((option) => {
        if (option === 'customSort') {
          optionsArray.push({
            id: 'custom-sort-separator',
            type: 'separator',
          });
        }

        return optionsArray.push({
          id: option,
          label: getConfigFieldLabel(option),
          onSelect: () => onSortChange(option, direction),
          suffix: option === currentSortBy ? <CheckIcon className="size-4" /> : null,
          type: 'item',
        });
      });

      return optionsArray;
    }, [currentSortBy, direction, onSortChange, sortOptions]);

    return (
      <ButtonGroup>
        <DropdownMenuWithActionSheet
          options={options}
          trigger={
            <Button appearance="ghost" color="grey" data-testid={`${testId}-MENU`} size="medium">
              {getConfigFieldLabel(currentSortBy)}
            </Button>
          }
          title="Sort by"
        />
        {supportsDirection && (
          <Tooltip label="Sort ascending/descending">
            <IconButton
              appearance="ghost"
              color="grey"
              data-testid={`${testId}-DIRECTION`}
              data-sort={currentSortBy}
              label="Sort ascending/descending"
              onClick={() => {
                direction === SortDirection.desc
                  ? onSortDirectionChange(SortDirection.asc)
                  : onSortDirectionChange(SortDirection.desc);
              }}
              size="medium"
            >
              <ArrowDown
                className={classNames('h-4 w-4', direction === SortDirection.asc ? 'rotate-180' : 'rotate-0')}
              />
            </IconButton>
          </Tooltip>
        )}
      </ButtonGroup>
    );
  },
);

SortMenu.displayName = 'SortMenu';
