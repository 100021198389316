import { Ellipsis } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { useRouter } from 'next/router';
import { ComponentProps, ComponentPropsWithoutRef, Fragment, useMemo } from 'react';

import { BreadcrumbsButton, BreadcrumbsButtonProps } from './BreadcrumbsButton';
import { BreadcrumbsItem, BreadcrumbsItemProps } from './BreadcrumbsItem';
import { BreadcrumbsLink, BreadcrumbsLinkProps } from './BreadcrumbsLink';
import { BreadcrumbsSeparator } from './BreadcrumbsSeparator';

export const breadcrumbs = tailwindVariants({
  base: 'flex items-center gap-1 text-grey-9',
  variants: {
    size: {
      small: 'text-12',
      medium: 'text-14',
      large: 'text-16',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export type BreadcrumbsButtonType = Pick<BreadcrumbsButtonProps, 'onClick' | 'prefix' | 'suffix'> & {
  label: string;
  type: 'button';
};

export type BreadcrumbsItemType = Pick<BreadcrumbsItemProps, 'prefix' | 'suffix'> & {
  label: string;
  type: 'item';
};

export type BreadcrumbsLinkType = Pick<BreadcrumbsLinkProps, 'href' | 'prefix' | 'suffix'> & {
  label: string;
  type: 'link';
};

export type BreadcrumbsItem = (BreadcrumbsItemType | BreadcrumbsLinkType | BreadcrumbsButtonType) &
  Pick<ComponentProps<'div'>, 'className'> & {
    id: string;
  };

export type BreadcrumbsVariants = VariantProps<typeof breadcrumbs>;

export type BreadcrumbsProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
  BreadcrumbsVariants & {
    isCompact?: boolean;
    items: BreadcrumbsItem[];
    separator?: string;
    visibleItemsAtEnd?: number;
  };

export const Breadcrumbs = ({
  className,
  isCompact: _isCompact = false,
  items = [],
  separator = '/',
  size = 'medium',
  visibleItemsAtEnd = 1,
  ...restOfProps
}: BreadcrumbsProps) => {
  const { push } = useRouter();
  const isCompact = _isCompact && items.length > 2;

  const itemRenderer = (item: BreadcrumbsItem) => {
    const isLastItem = items.indexOf(item) === items.length - 1;

    const element =
      item.type === 'button' ? (
        <BreadcrumbsButton
          className={item.className}
          key={`breadcrumbs-button-${item.id}`}
          onClick={item.onClick}
          prefix={item.prefix}
          suffix={item.suffix}
        >
          {item.label}
        </BreadcrumbsButton>
      ) : item.type === 'item' ? (
        <BreadcrumbsItem
          className={item.className}
          key={`breadcrumbs-item-${item.id}`}
          prefix={item.prefix}
          suffix={item.suffix}
        >
          {item.label}
        </BreadcrumbsItem>
      ) : item.type === 'link' ? (
        <BreadcrumbsLink
          className={item.className}
          href={item.href}
          key={`breadcrumbs-link-${item.id}`}
          prefix={item.prefix}
          suffix={item.suffix}
        >
          {item.label}
        </BreadcrumbsLink>
      ) : null;

    return isLastItem ? (
      element
    ) : (
      <Fragment key={`breadcrumbs-fragment-${item.id}`}>
        {element}
        <BreadcrumbsSeparator key={`breadcrumbs-separator-${item.id}`}>{separator}</BreadcrumbsSeparator>
      </Fragment>
    );
  };

  const filteredItems = useMemo(() => {
    if (isCompact) {
      return items.slice(-visibleItemsAtEnd);
    }

    return items;
  }, [isCompact, items, visibleItemsAtEnd]);

  const menuOptions = useMemo<DropdownMenuOption[]>(() => {
    return items.slice(1, items.length - visibleItemsAtEnd).map((item) => ({
      id: item.id,
      label: item.label,
      onSelect: () => {
        if (item.type === 'link') {
          push(item.href);
        }

        if (item.type === 'button') {
          item.onClick();
        }
      },
      type: 'item',
    }));
  }, [items, push, visibleItemsAtEnd]);

  return (
    <div className={breadcrumbs({ size, class: className })} {...restOfProps}>
      {isCompact && (
        <>
          {itemRenderer(items[0])}
          <DropdownMenu
            data-testid="MORE_ANCESTORS_DROPDOWN"
            align="start"
            sideOffset={8}
            trigger={
              <IconButton
                data-testid="MORE_ANCESTORS_BUTTON"
                appearance="ghost"
                className="text-grey-8"
                color="grey"
                icon={Ellipsis}
                label="More ancestors"
                size="small"
              />
            }
          >
            {renderDropdownItems({ options: menuOptions })}
          </DropdownMenu>
          <BreadcrumbsSeparator>{separator}</BreadcrumbsSeparator>
        </>
      )}
      {filteredItems.map(itemRenderer)}
    </div>
  );
};
